import React, {createRef} from 'react';
import ReactDOM from 'react-dom/client';
// import { useState } from 'react';
import { useEffect } from 'react';
import './index.css';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// import Поиск по выпускам Жить Здорово';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

class Lifecycle extends React.Component{ 

  // const [userChoice, setUserChoice] = useState("")

  constructor(props) {
    super(props);
    this.reset();
    window.addEventListener('resize', () => this.handleWindowSizeChange(this));
  }

  reset() {
    // Always set the initial state in its own function, so that
    // you can trivially reset your components at any point.
    this.state = {
      width: window.innerWidth,
      budgetValue: '500',
      inputValue: '',
      searchPhrase: '',
      isSearching: false,
      fragments: [],
      fragmentsPageIndex: null,
      fragmentsTotalPages: null,
      fragmentPageLinks: [],
      urlMp4: null,
      title: null,
      description: null
    };
  }

  handleWindowSizeChange(that) {
    // console.log("handleWindowSizeChange");
    // console.log(window.innerWidth);
    that.setState({
      width: window.innerWidth
    });
  }

  updateInputValue(evt) {
    const val = evt.target.value;
    this.setState({
      inputValue: val
    });
  }

  updateBudgetValue(evt) {
    const val = evt.target.value;
    this.setState({
      budgetValue: val
    });
  }

  handleSearch(that, from) {
    // console.log("handleSearch");
    // console.log(that);
    // console.log(that.state.searchPhrase);
    // console.log("width");
    // console.log(that.state.width);

    that.setState({
      isSearching: true
    });

    // fetch("http://localhost:8080/fragments/1234/search", {
    fetch("https://api.jzpro2.com/fragments/1234/search", {
      method: "POST",
      body: JSON.stringify({
        from: from,
        pageSize: 10,
        text: that.state.searchPhrase
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);

      let fragmentPageLinks = [];
      for (let i = 1; i <= data["totalPages"]; i++) {
        const isCurrent = i == data["pageIndex"];
        const from = (i - 1) * data["pageSize"];
        fragmentPageLinks[fragmentPageLinks.length] = {'isCurrent': isCurrent, 'index': i, 'from': from};
      }

      that.setState({
        fragments: data["fragments"],
        fragmentsPageIndex: data["pageIndex"],
        fragmentsTotalPages: data["totalPages"],
        isSearching: false,
        fragmentPageLinks: fragmentPageLinks
      });
    });
  }

  handleKeyPress(that, target) {
    if(target.charCode==13){
      that.handleSearch(that, 0);
    } 
  }

  handleView(that, mp4350, title, description) {
    console.log("handleView");
    console.log(that);
    console.log(mp4350);

    that.setState({
      urlMp4: mp4350,
      title: title,
      description: description
    });
  }

  render() {
    return (
      <React.StrictMode>
        <Container className="p-3">
          <Container className={this.state.fragments.length == 0 && this.state.urlMp4 == null ? 'p-5 mb-4 bg-light rounded-3 d-block' : 'd-none'}>
            <h1 className="header">Поиск по выпускам Жить Здорово</h1>
            <div>&nbsp;</div>

            <Form.Group className="m-0">
              <Form.Label htmlFor="inputPhrase">Введите, что вас интересует</Form.Label>
              <Form.Control
                id="inputPhrase"
                aria-describedby="phraseHelpBlock"
                value={this.state.searchPhrase}
                onChange={e => this.setState({ searchPhrase: e.target.value })}
                onKeyPress={(target) => this.handleKeyPress(this, target)}
              />
              <Form.Text id="phraseHelpBlock" muted>
                Например, сердце или болезнь Аддисона
              </Form.Text>
              <div>&nbsp;</div>

              <Button variant="primary" type="button" disabled={this.state.isSearching} onClick={!this.state.isSearching ? () => this.handleSearch(this, 0) : null}>
                {this.state.isSearching ? 'Ищу…' : 'Искать'}
              </Button>
            </Form.Group>
          </Container>
          <Container className={this.state.fragments.length > 0 && this.state.urlMp4 == null ? 'd-block' : 'd-none'}>
            <h1 className="header">Поиск по выпускам Жить Здорово</h1>
            <div>&nbsp;</div>

            <Container className={this.state.width <= 768 ? 'd-block' : 'd-none'}>
              <Row xs="1" sm="1" md="1" lg="1">
                <Col>
                  <ButtonGroup aria-label="links">
                    {
                      this.state.fragmentPageLinks.map((fragmentPageLink) => 
                        <Button variant={fragmentPageLink.isCurrent ? 'secondary' : 'primary'} onClick={!this.state.isCurrent ? () => this.handleSearch(this, fragmentPageLink.from) : null}>
                          {fragmentPageLink.index}
                        </Button>
                      )
                    }
                  </ButtonGroup>
                  <div>&nbsp;</div>
                </Col>
                <Col>
                  <Button variant="primary" type="button" onClick={() => this.setState({fragments: []})}>
                    Новый поиск
                  </Button>
                </Col>
              </Row>
            </Container>
            <Container fluid="true" className={this.state.width > 768 ? 'd-block' : 'd-none'}>
              <ButtonGroup aria-label="links">
                <Button variant="secondary" disabled="true">Дальнейшие страницы</Button>
                {
                  this.state.fragmentPageLinks.map((fragmentPageLink) => 
                    <Button variant={fragmentPageLink.isCurrent ? 'secondary' : 'primary'} onClick={!this.state.isCurrent ? () => this.handleSearch(this, fragmentPageLink.from) : null}>
                      {fragmentPageLink.index}
                    </Button>
                  )
                }
              </ButtonGroup>
              <Button className="float-end" variant="primary" type="button" onClick={() => this.setState({fragments: []})}>
                Новый поиск
              </Button>
            </Container>
            <div>&nbsp;</div>

            <Row xs="1" sm="2" md="2" lg="3">
            {
              this.state.fragments.map((fragment) => 
                <Col className="d-flex">
                  <Card className="flex-fill" className="productlist">
                    <Card.Img variant="top" src={fragment.poster} />
                    <Card.Body>
                      <Card.Title>{fragment.title}</Card.Title>
                      <Card.Text>{fragment.description}</Card.Text>
                      <Button variant="primary" onClick={() => this.handleView(this, fragment.mp4350, fragment.title, fragment.description)}>Просмотреть</Button>
                    </Card.Body>
                  </Card>
                </Col>
              )
            }
            </Row>
            <div>&nbsp;</div>

            <Container className={this.state.width <= 768 ? 'd-block' : 'd-none'}>
              <Row xs="1" sm="1" md="1" lg="1">
                <Col>
                  <ButtonGroup aria-label="links">
                    {
                      this.state.fragmentPageLinks.map((fragmentPageLink) => 
                        <Button variant={fragmentPageLink.isCurrent ? 'secondary' : 'primary'} onClick={!this.state.isCurrent ? () => this.handleSearch(this, fragmentPageLink.from) : null}>
                          {fragmentPageLink.index}
                        </Button>
                      )
                    }
                  </ButtonGroup>
                  <div>&nbsp;</div>
                </Col>
                <Col>
                  <Button variant="primary" type="button" onClick={() => this.setState({fragments: []})}>
                    Новый поиск
                  </Button>
                </Col>
              </Row>
            </Container>
            <Container fluid="true" className={this.state.width > 768 ? 'd-block' : 'd-none'}>
              <ButtonGroup aria-label="links">
                <Button variant="secondary" disabled="true">Дальнейшие страницы</Button>
                {
                  this.state.fragmentPageLinks.map((fragmentPageLink) => 
                    <Button variant={fragmentPageLink.isCurrent ? 'secondary' : 'primary'} onClick={!this.state.isCurrent ? () => this.handleSearch(this, fragmentPageLink.from) : null}>
                      {fragmentPageLink.index}
                    </Button>
                  )
                }
              </ButtonGroup>
              <Button className="float-end" variant="primary" type="button" onClick={() => this.setState({fragments: []})}>
                Новый поиск
              </Button>
            </Container>
          </Container>
          <Container className={this.state.urlMp4 != null ? 'd-block' : 'd-none'}>
            <h1 className="header">Поиск по выпускам Жить Здорово</h1>
            <div>&nbsp;</div>

            <Button variant="primary" type="button" onClick={() => this.setState({urlMp4: null})}>
              Назад
            </Button>
            <div>&nbsp;</div>

            <video width="auto" key={this.state.urlMp4} controls>
              <source src={this.state.urlMp4} type="video/mp4"/>
            </video>
            <div>&nbsp;</div>

            <h2>{this.state.title}</h2>
            <h5>{this.state.description}</h5>
            <div>&nbsp;</div>

            <Button variant="primary" type="button" onClick={() => this.setState({urlMp4: null})}>
              Назад
            </Button>
          </Container>
        </Container>
      </React.StrictMode>
    );
  }
}


const element=<Lifecycle></Lifecycle>;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(element);

// <Col className="d-flex" style={{minWidth: '320px'}}>
// <Col key={id} lg={3} md={4} sm={6} xs={12} className="mb-4">
